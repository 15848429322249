import React from 'react';
import { 
    useHistory,
    useLocation
} from "react-router-dom";
import i18next from 'i18next';
import './Search.sass'



export default function Search(props) {
    let location = useLocation();
    let history = useHistory();

    function onSearchTypeHandle(e) {
        props.setSearchString(e.target.value);
        props.setCurrentCategories([])
        if(e.target.value.length > 0 && location.pathname !== '/search') {
            history.push('/search')
        }
    }

    return (
        <input 
            className={`global-search ${props.className}`}
            type="search"
            autoFocus={ props.autoFocus }
            value={ props.searchString }
            onChange={ e => onSearchTypeHandle(e) }
            placeholder={ i18next.t('Type to search') }/>
    )
}