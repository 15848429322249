import React from 'react';
import { useEffect } from "react";
import {
    useLocation
} from "react-router-dom";
import CategoryLayout from './../layouts/CategoryContainer';
import Search from './../components/common/Search/SearchContainer';
import ClearButton from './../components/common/ClearButton/ClearButtonContainer';
import filterCardsBySearch from './../lib/array/filterCardsBySearch';
import filterCardsBySearchCategories from './../lib/array/filterCardsBySearchCategories';
import filterCardsByCategories from './../lib/array/filterCardsByCategories';
import noDuplicatesArray from './../lib/array/noDuplicatesArray';

import orderBy from './../lib/array/orderBy';



function ScrollToTop() {
    const { pathname, search } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname, search]);
  
    return null;
}

export default function SearchPage(props) {
    function arrayToRender() {
        let cards = props.cardsList;
        cards = filterCardsBySearch(cards, props.searchString); // filter by search
        let cardsFormCategories = filterCardsBySearchCategories(props.groupesList, props.searchString); // filter by categories
        cards = orderBy(cards, props.filterOrder);
        
        let searchCurrentCategories = [...props.categoriesSelected, ...cardsFormCategories]
        const noDuplicatesSearchCurrentCategories = searchCurrentCategories.filter((value, index, item) => item.indexOf(value) === index);
        let newCards = filterCardsByCategories(props.cardsList, noDuplicatesSearchCurrentCategories, props.groupesList); // filter by categories names     

        let noDuplicatesCards = [...cards, ...newCards];
        let cardSearchCategories = noDuplicatesArray(noDuplicatesCards);
        
        return cardSearchCategories;
    }

    return (
        <>
        <ScrollToTop />
        <CategoryLayout
            titleMarkup={ 
                <div className="search-row">
                    <Search 
                        className="search-row__input"
                        autoFocus={ true } />
                    <ClearButton
                        className="search-row__button" />
                </div>
            }
            cards={ arrayToRender() } />
        </>
    )
}