export default function filterCardsBySearchCategories(categories, string) {

    const searchString = string.toLowerCase();

    if (!!string) {

        const matchingCategoryIds = categories
            .map(item => 
                item.categories
                    .filter(category => 
                        category.title.toLowerCase().includes(searchString)
                    )
                    .map(category => category.id)
            )
            .flat();

        return matchingCategoryIds;
    }

    return [];

}
