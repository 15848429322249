import React from 'react';
import { 
    useHistory,
    useLocation
} from "react-router-dom";
import './CategoriesCheckboxList.sass';



export default function CategoriesCheckboxList(props) {
    let location = useLocation();
    let history = useHistory()
    
    function activeCheckbox(id) {
        return props.categoriesSelected.includes(id) ? 'checked' : false;
    }

    function handleCategoriesetter(categories) {
        let array = props.categoriesSelected;
        if(categories.length === 1){
            for(let category of categories) {
                if(array.indexOf(category) === -1) {
                    array.push(category);
                } else {
                    array.splice(array.indexOf(category), 1);
                }
            }
        } else {
            let checkSigned = array.filter(item => {
                return categories.indexOf(item) !== -1;
            }).length !== 0;
            if(!checkSigned) {
                array = [...array, ...categories];
            } else {
                array = array.filter(item => {
                    return categories.indexOf(item) === -1;
                });
            }
        }
        if(location.pathname !== '/library') {
            history.push('/library');
        }
        props.setCurrentCategories([...array]);
        props.setSearchString('');
    }
    
    return (
        <ul className="categories-list">
            { props.categories.map(category => {
                if(category.length !== 0) {
                    return (
                        <li
                            className="categories-list__item"
                            key={ category.id }>
                            <label>
                                <input 
                                    type="checkbox"
                                    checked={ activeCheckbox(category.id) }
                                    onChange={ () => handleCategoriesetter([category.id]) }
                                    className="mr-2"/>
                                <span>{ category.title }</span>
                            </label>
                        </li>
                    )
                } else return false
            }) }
        </ul>
    )
}