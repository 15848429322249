import React from 'react';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import Header from './../components/common/Header/HeaderContainer';
import FilterHeader from '../components/desktop/FilterHeader/FilterHeaderContainer';
import Footer from './../components/common/Footer/Footer';
import MobileNav from './../components/mobile/MobileNav/MobileNavContainer';
import Sort from './../components/desktop/Sort/SortContainer';
import ToggleCardList from './../components/desktop/ToggleCardList/ToggleCardListContainer';
import CardsList from './../components/common/CardsList/CardsListContainer';
import orderBy from '../lib/array/orderBy';
import noDuplicatesArray from './../lib/array/noDuplicatesArray';
import inResponsiveSizes from './../lib/ui/inResponsiveSizes';

import CategoryLoader from './../components/common/CategoryLoader/CategoryLoader';
import './Category.sass';



export default function CategoryLayout(props) {
    function arrayToRender() {
        let cards = props.cards;
        cards = orderBy(cards, props.order);
        cards = noDuplicatesArray(cards);
        return cards;
    }

    return (
        <>
            <Header />
            { !inResponsiveSizes(['xs', 'sm'], props.screenSize) &&
                <FilterHeader />
            }
            { !props.allCardsLoaded ? (
                <CategoryLoader />
            ) : (
                <div className="page__content">
                    <section className="category">
                        {/* Backlink */}
                        { props.backlinkMarkup ? 
                            <>
                                { props.backlinkMarkup }
                            </>
                        :
                            <NavLink
                                className="category__backlink home-backlink"
                                to={`/`}>
                                { i18next.t('Home') }
                            </NavLink>
                        }

                        {/* Title */}

                        { props.titleMarkup ? 
                            <div className="category__title">
                                { props.titleMarkup }
                            </div>
                        :
                            <h1 className="category__title">{ props.title }</h1>
                        } 

                        {/* Description */}
                        { props.content &&
                            <p 
                                className="category__description"
                                dangerouslySetInnerHTML={{__html: props.content }}></p>
                        }

                        {/* Cards length counter */}
                        <div className="category__counter">{ arrayToRender().length } { i18next.t('Entries') }</div>

                        {/* Desktop view togglers */}
                        { !inResponsiveSizes(['xs', 'sm'], props.screenSize) &&
                            <div className="category__view-funcs">
                                <ToggleCardList />
                                <Sort />
                            </div> 
                        }

                        {/* Cards list */}
                        { arrayToRender().length !== 0 ?
                            <CardsList
                                className="category__cards-list"
                                cards={ arrayToRender() }/>
                        :
                            <div className="category__cards-list category__cards-list--empty">
                                <svg
                                    height="300"
                                    width="300"
                                    fill="#BEBEBE">
                                    <use xlinkHref="/svg/images.svg#empty-search" />
                                </svg>
                                <h1>{ i18next.t('Nothing Found') }</h1>
                            </div>
                        }
                    </section>
                </div>
            )}
            { inResponsiveSizes(['xs', 'sm'], props.screenSize) &&
                <MobileNav />
            } 
            <Footer />
        </>
    )
}