import React from 'react';
import Search from './Search';
import { connect } from 'react-redux';
import { 
    setSearchString,
    setCurrentCategories
} from '../../../store/filter/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class SearchContainer extends React.Component {
    render() {
        return (
            <Search {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        searchString: state.filter.searchString
    }
}
  
const mapDispatchToProps = {
    setSearchString,
    setCurrentCategories
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer)